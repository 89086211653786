var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("el-col", { staticClass: "col-title", attrs: { span: 24 } }, [
        _vm._v("条件列表"),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.conditionTableLoading,
              expression: "conditionTableLoading",
            },
          ],
          ref: "drugTable",
          attrs: {
            border: "",
            stripe: "",
            "header-cell-style": { "text-align": "center" },
            "cell-style": { "text-align": "center" },
            data: _vm.conditionTableData,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", "min-width": "50", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "集采剂型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.batchFormList, function (item) {
                    return _c(
                      "el-tag",
                      {
                        staticClass: "col-tag",
                        attrs: { size: "mini", type: "info" },
                      },
                      [_vm._v(_vm._s(item.name) + " ")]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "关联药物/药物组" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.refType == 1
                      ? [
                          _c("span", [_vm._v("药物:")]),
                          _vm._l(scope.row.drugList, function (item) {
                            return _c(
                              "el-tag",
                              {
                                staticClass: "col-tag",
                                attrs: { size: "mini" },
                              },
                              [_vm._v(_vm._s(item.drugName))]
                            )
                          }),
                        ]
                      : _vm._e(),
                    scope.row.refType == 2
                      ? [
                          _c("span", [_vm._v("药物组:")]),
                          _vm._l(scope.row.drugGroupList, function (item) {
                            return _c(
                              "el-tag",
                              {
                                staticClass: "col-tag",
                                attrs: { size: "mini" },
                              },
                              [_vm._v(_vm._s(item.drugGroupName) + " ")]
                            )
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "可替代类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("el-tag", { attrs: { size: "mini" } }, [
                          _vm._v("完全可替代"),
                        ])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c(
                          "el-tag",
                          { attrs: { type: "success", size: "mini" } },
                          [_vm._v("大部分可替代")]
                        )
                      : _vm._e(),
                    scope.row.type == 3
                      ? _c(
                          "el-tag",
                          { attrs: { type: "info", size: "mini" } },
                          [_vm._v("一定程度可替代")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.doEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#F56C6C" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.doDel(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-col", { staticClass: "col-title", attrs: { span: 24 } }, [
        _vm._v("关联制剂列表"),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.ppTableLoading,
              expression: "ppTableLoading",
            },
          ],
          ref: "drugTable",
          attrs: {
            border: "",
            stripe: "",
            "header-cell-style": { "text-align": "center" },
            "cell-style": { "text-align": "center" },
            data: _vm.ppTableData,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", "min-width": "50", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { width: "120", property: "ppId", label: "制剂ID" },
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "200",
              property: "ppName",
              label: "制剂名称",
            },
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "可替代类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 1
                      ? _c("el-tag", [_vm._v("完全可替代")])
                      : _vm._e(),
                    scope.row.type == 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("大部分可替代"),
                        ])
                      : _vm._e(),
                    scope.row.type == 3
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("一定程度可替代"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.editConditionDialogVisible
        ? _c("edit-replace-drug", {
            attrs: {
              visible: _vm.editConditionDialogVisible,
              "batch-info": _vm.batchInfo,
              "condition-id": _vm.editConditionId,
            },
            on: {
              "update:visible": function ($event) {
                _vm.editConditionDialogVisible = $event
              },
              refreshTable: _vm.refreshTable,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }